import React from 'react'
import './footer.css'
import { footerbg, ffphone, ffclock, ffemail, ffmap } from '../assets'
const Footer = () => {
    return (
        <div className='footer'>
            <img src={footerbg} className='footerbg' alt='' />
            <div className='footer_text'>
                <div className='footer_row'>
                    <div className='footer_element'>
                        <img src={ffphone} />
                        <h2>(801) 426-4822</h2>
                    </div>
                    <div className='footer_element'>
                        <img src={ffclock} />
                        <h2>Mon - Fri: 8:00 - 5:00</h2>
                    </div>
                </div>
                <div className='footer_row_two'>
                    <div className='footer_element'>
                        <img src={ffemail} />
                        <h2>traditionsmill@gmail.com</h2>
                    </div>
                    <div className='footer_element'>
                        <img src={ffmap} />
                        <h2> 1876 N 2700 W Unit 1 Provo, Utah</h2>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default Footer