import './crown.css';
import { TC151, TC152, TC153, TC157 } from '../catalog_images/base_n_case/'
import { Cimg } from '../components'

function Basencase2() {
    return (
        <div className="crown">
            <div className='bbcrown_banner'>ARCHITRAVE</div>
            <div className='crown_container'>
                <div className='crown_container_images' >
                    <div className='image_container_container' >
                        <Cimg image={TC151} className='image_container' />
                        <Cimg image={TC152} className='image_container' />
                        <Cimg image={TC153} className='image_container' />
                        <Cimg image={TC157} className='image_container' />
                    </div>
                </div>
            </div>
        </div >
    );
}

export default Basencase2;
