import './crown.css';
import { HOME_NAVBAR, Footer, Cimg } from '../components'
import { Miscellaneous2, Miscellaneous3, Miscellaneous4, Miscellaneous5 } from '../catalog_pages'
import { TS401, TS402, Int_Jamb_Stock, Header, Ext_Jamb_Stock, TBK940 } from '../catalog_images/micellaneous/door_parts'
import { useContext } from 'react'
import { ModalContext } from '../Helper/Context'
function Miscellaneous() {
    const { modalUp, setModalUp } = useContext(ModalContext);

    return (
        <div className="crown">
            <div className='page_animate' />
            {modalUp ? (<div><HOME_NAVBAR /></div>
            ) : (<div className='navi'><div className='navi_2'><HOME_NAVBAR /></div></div>
            )}
            {modalUp ? (<div className='crown_banner' >MISCELLANEOUS</div>) : (<div className='crown_banner crowny'>MISCELLANEOUS</div>)}
            <div className='crown_container'>
                <div className='crown_container_images' >
                    <div className='image_container_container' >
                        <Cimg image={TS401} className='image_container' />
                        <Cimg image={TS402} className='image_container' />
                        <Cimg image={Int_Jamb_Stock} className='image_container' />
                        <Cimg image={Header} className='image_container' />
                        <Cimg image={Ext_Jamb_Stock} className='image_container' />
                        <Cimg image={TBK940} className='image_container' />

                    </div>
                </div>
            </div>
            <Miscellaneous2 />
            <Miscellaneous3 />
            <Miscellaneous4 />
            <Miscellaneous5 />
            {modalUp ? (<div><Footer /></div>
            ) : (<div className='footy'><div className='navi_2'><Footer /></div></div>
            )}
            <div id='footer' />
        </div >
    );
}

export default Miscellaneous;
