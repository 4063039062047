import './crown.css';
import { THR1, THR2, TQR1, TQR2, TQR3 } from '../catalog_images/panel_mould/rounds'


function Panelmould3() {
    return (
        <div className="crown">
            <div className='bbcrown_banner'>ROUNDS</div>
            <div className='crown_container'>
                <div className='crown_container_images' >
                    <div className='image_container_container' >
                        <img src={THR1} className='image_container' />
                        <img src={THR2} className='image_container' />
                        <img src={TQR1} className='image_container' />
                        <img src={TQR2} className='image_container' />
                        <img src={TQR3} className='image_container' />
                    </div>
                </div>
            </div>
        </div >
    );
}

export default Panelmould3;
