import React from 'react'
import { Home, About, Catalog, Contact, Gallery, Login, Admin } from './pages'
import { Crown, Basencase, Cap, Chairrail, Nosing, Panelmould, Miscellaneous, Cnc } from './catalog_pages'
import { BrowserRouter, Route, Routes, Navigate, useNavigate } from 'react-router-dom';
import { useContext, useState } from "react";
import { AuthContext } from "./context/AuthContext";
import './App.css'
import { ModalContext } from './Helper/Context';

const App = () => {
  const { currentUser } = useContext(AuthContext);
  const RequireAuth = ({ children }) => {
    return currentUser ? children : <Navigate to="/login" />;
  };


  const [modalUp, setModalUp] = useState(true)
  return (
    <ModalContext.Provider value={{ modalUp, setModalUp }}>
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/catalog' element={<Catalog />} />
          <Route path='/gallery' element={<Gallery />} />
          <Route path='/about' element={<About />} />
          <Route path='/contact' element={<Contact />} />
          <Route path='/crown' element={<Crown />} />
          <Route path='/basencase' element={<Basencase />} />
          <Route path='/cap' element={<Cap />} />
          <Route path='/chairrail' element={<Chairrail />} />
          <Route path='/nosing' element={<Nosing />} />
          <Route path='/panelmould' element={<Panelmould />} />
          <Route path='/miscellaneous' element={<Miscellaneous />} />
          <Route path='/cnc' element={<Cnc />} />
          <Route path='/login' element={<Login />} />
          <Route path='/admin' element={<Admin />} />
        </Routes>
      </BrowserRouter>
    </ModalContext.Provider>

  )
}

export default App