import './crown.css';
import { HOME_NAVBAR, Footer, Cimg } from '../components'
import { Cnc2 } from '../catalog_pages'
import { DD_Center_Circle, DD_Double_Diamond, DD_Half_Circle, DD_Crossroads, DD_Lock_Line_Vent, DD_Lock_Star_Vent, DD_Single_Pyramid, DD_Double_Pyramid, } from '../catalog_images/cnc/door_panel_designs'
import { useContext } from 'react'
import { ModalContext } from '../Helper/Context'
function Cnc() {
    const { modalUp, setModalUp } = useContext(ModalContext);

    return (
        <div className="crown">
            <div className='page_animate' />
            {modalUp ? (<div><HOME_NAVBAR /></div>
            ) : (<div className='navi'><div className='navi_2'><HOME_NAVBAR /></div></div>
            )}
            {modalUp ? (<div className='crown_banner' >CNC</div>) : (<div className='crown_banner crowny'>CNC</div>)}
            <div className='crown_container'>
                <div className='crown_container_images' >
                    <div className='image_container_container' >
                        <Cimg image={DD_Center_Circle} className='image_container' />
                        <Cimg image={DD_Double_Diamond} className='image_container' />
                        <Cimg image={DD_Half_Circle} className='image_container' />
                        <Cimg image={DD_Crossroads} className='image_container' />
                        <Cimg image={DD_Lock_Line_Vent} className='image_container' />
                        <Cimg image={DD_Lock_Star_Vent} className='image_container' />
                        <Cimg image={DD_Single_Pyramid} className='image_container' />
                        <Cimg image={DD_Double_Pyramid} className='image_container' />
                    </div>
                </div>
            </div>
            <Cnc2 />
            {modalUp ? (<div><Footer /></div>
            ) : (<div className='footy'><div className='navi_2'><Footer /></div></div>
            )}
            <div id='footer' />
        </div >
    );
}

export default Cnc;
