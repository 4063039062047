import './crown.css';
import { HOME_NAVBAR, Footer, Cimg } from '../components'
import { Basencase2, Basencase3 } from './'
import { TBC00_1, TBC01, TBC02, TBC03, TBC03A, TBC04, TBC05, TBC06, TBC07, TBC08B, TBC09, TBC10, TBC11, TBC12, TBC13, TBC14, TBC15, TBC16, TBC17, TBC19_1, TBC20, TBC21, TBC22, TBC24, TBC25_3, TBC26, TBC27, TBC28, TBC29, TBC29A, TBC32, TBC33, TBC34, TBC36, TBC37, TBC42, TBC43, TBC44, TBC45_1, TBC47, TBC48, TBC49, TBC60, TBC61, TBC62, TBC63, TBC64, TBC66, TBC68, TBC69, TBC70, TBC77, TBC78, TBC79, TBC80, TBC81, TBC82, TBC218B, TCC105_105AB } from '../catalog_images/base_n_case/'
import { useContext } from 'react'
import { ModalContext } from '../Helper/Context'
function Basencase() {
    const { modalUp, setModalUp } = useContext(ModalContext);

    return (
        <div className="crown">
            <div className='page_animate' />
            {modalUp ? (<div><HOME_NAVBAR /></div>
            ) : (<div className='navi'><div className='navi_2'><HOME_NAVBAR /></div></div>
            )}
            {modalUp ? (<div className='crown_banner' >BASE & CASE</div>) : (<div className='crown_banner crowny'>BASE & CASE</div>)}
            <div className='crown_container'>
                <div className='crown_container_images' >
                    <div className='image_container_container' >
                        <Cimg image={TBC00_1} className='image_container' />
                        <Cimg image={TBC01} className='image_container' />
                        <Cimg image={TBC02} className='image_container' />
                        <Cimg image={TBC03} className='image_container' />
                        <Cimg image={TBC03A} className='image_container' />
                        <Cimg image={TBC04} className='image_container' />
                        <Cimg image={TCC105_105AB} className='image_container' />
                        <Cimg image={TBC05} className='image_container' />
                        <Cimg image={TBC06} className='image_container' />
                        <Cimg image={TBC07} className='image_container' />
                        <Cimg image={TBC08B} className='image_container' />
                        <Cimg image={TBC09} className='image_container' />
                        <Cimg image={TBC10} className='image_container' />
                        <Cimg image={TBC11} className='image_container' />
                        <Cimg image={TBC12} className='image_container' />
                        <Cimg image={TBC13} className='image_container' />
                        <Cimg image={TBC14} className='image_container' />
                        <Cimg image={TBC15} className='image_container' />
                        <Cimg image={TBC16} className='image_container' />
                        <Cimg image={TBC17} className='image_container' />
                        <Cimg image={TBC19_1} className='image_container' />
                        <Cimg image={TBC20} className='image_container' />
                        <Cimg image={TBC21} className='image_container' />
                        <Cimg image={TBC218B} className='image_container' />
                        <Cimg image={TBC22} className='image_container' />
                        <Cimg image={TBC24} className='image_container' />
                        <Cimg image={TBC25_3} className='image_container' />
                        <Cimg image={TBC26} className='image_container' />
                        <Cimg image={TBC27} className='image_container' />
                        <Cimg image={TBC28} className='image_container' />
                        <Cimg image={TBC29} className='image_container' />
                        <Cimg image={TBC29A} className='image_container' />
                        <Cimg image={TBC32} className='image_container' />
                        <Cimg image={TBC33} className='image_container' />
                        <Cimg image={TBC34} className='image_container' />
                        <Cimg image={TBC36} className='image_container' />
                        <Cimg image={TBC37} className='image_container' />
                        <Cimg image={TBC42} className='image_container' />
                        <Cimg image={TBC43} className='image_container' />
                        <Cimg image={TBC44} className='image_container' />
                        <Cimg image={TBC45_1} className='image_container' />
                        <Cimg image={TBC47} className='image_container' />
                        <Cimg image={TBC48} className='image_container' />
                        <Cimg image={TBC49} className='image_container' />
                        <Cimg image={TBC60} className='image_container' />
                        <Cimg image={TBC61} className='image_container' />
                        <Cimg image={TBC62} className='image_container' />
                        <Cimg image={TBC63} className='image_container' />
                        <Cimg image={TBC64} className='image_container' />
                        <Cimg image={TBC66} className='image_container' />
                        <Cimg image={TBC68} className='image_container' />
                        <Cimg image={TBC69} className='image_container' />
                        <Cimg image={TBC70} className='image_container' />
                        <Cimg image={TBC77} className='image_container' />
                        <Cimg image={TBC78} className='image_container' />
                        <Cimg image={TBC79} className='image_container' />
                        <Cimg image={TBC80} className='image_container' />
                        <Cimg image={TBC81} className='image_container' />
                        <Cimg image={TBC82} className='image_container' />
                    </div>
                </div>


            </div>
            <Basencase2 />
            <Basencase3 />
            {modalUp ? (<div><Footer /></div>
            ) : (<div className='footy'><div className='navi_2'><Footer /></div></div>
            )}
            <div id='footer' />
        </div >
    );
}

export default Basencase;
