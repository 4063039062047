import './crown.css';
import { TR1_1, TR2_1 } from '../catalog_images/micellaneous/hand_rail'
import { Cimg } from '../components'


function Miscellaneous4() {
    return (
        <div className="crown">
            <div className='bbcrown_banner'>HANDRAIL</div>
            <div className='crown_container'>
                <div className='crown_container_images' >
                    <div className='image_container_container' >
                        <Cimg image={TR1_1} className='image_container' />
                        <Cimg image={TR2_1} className='image_container' />
                    </div>
                </div>
            </div>
        </div >
    );
}

export default Miscellaneous4;
