import './crown.css';
import { HOME_NAVBAR, Footer, Cimg } from '../components'
import { TCR491, TCR500, TCR501, TCR502_1, TCR503, TCR503A, TCR504, TCR505, TCR505B, TCR506, TCR507, TCR508, TCR509, TCR510, TCR511, TCR512, TCR513, TCR514, TCR515_1, TCR516, TCR517, TCR518, TCR519, TCR520, TCR521, TCR522, TCR523, TCR524, TCR525, TCR526, TCR527, TCR528, TCR531, TCR532, TCR534, TCR536, TCR537, TCR538, TCR540, TCR541, TCR542, TCR545, TCR554, TCR555, TCR560_1, TCR566, TCR567, TCR568, TCR569_1, TCR569_5_1, TCR570, TCR571B, TCR572, TCR573, TCR574, TCR575, TCR576, TCR576B, TCR576p5, TCR577, TCR578, TCRB2, TCRB3_1, TMC1, TMC2, TMC3_1, TMC4, TMC5, TMC6, TMC7 } from '../catalog_images/crown'
import { useContext } from 'react'
import { ModalContext } from '../Helper/Context'

function Crown() {
    const { modalUp, setModalUp } = useContext(ModalContext);

    return (
        <div className="crown">
            <div className='page_animate' />
            {modalUp ? (<div><HOME_NAVBAR /></div>
            ) : (<div className='navi'><div className='navi_2'><HOME_NAVBAR /></div></div>
            )}
            {modalUp ? (<div className='crown_banner' >CROWN</div>) : (<div className='crown_banner crowny'>CROWN</div>)}

            <div className='crown_container'>
                <div className='crown_container_images' >
                    <div className='image_container_container' >
                        <Cimg image={TCR491} />
                        <Cimg image={TCR500} />
                        <Cimg image={TCR501} />
                        <Cimg image={TCR502_1} />
                        <Cimg image={TCR503} />
                        <Cimg image={TCR503A} />
                        <Cimg image={TCR504} />
                        <Cimg image={TCR505} />
                        <Cimg image={TCR506} />
                        <Cimg image={TCR507} />
                        <Cimg image={TCR508} />
                        <Cimg image={TCR509} />
                        <Cimg image={TCR510} />
                        <Cimg image={TCR511} />
                        <Cimg image={TCR512} />
                        <Cimg image={TCR513} />
                        <Cimg image={TCR514} />
                        <Cimg image={TCR515_1} />
                        <Cimg image={TCR516} />
                        <Cimg image={TCR517} />
                        <Cimg image={TCR518} />
                        <Cimg image={TCR519} />
                        <Cimg image={TCR520} />
                        <Cimg image={TCR521} />
                        <Cimg image={TCR522} />
                        <Cimg image={TCR523} />
                        <Cimg image={TCR524} />
                        <Cimg image={TCR525} />
                        <Cimg image={TCR526} />
                        <Cimg image={TCR527} />
                        <Cimg image={TCR528} />
                        <Cimg image={TCR531} />
                        <Cimg image={TCR532} />
                        <Cimg image={TCR534} />
                        <Cimg image={TCR536} />
                        <Cimg image={TCR537} />
                        <Cimg image={TCR538} />
                        <Cimg image={TCR540} />
                        <Cimg image={TCR541} />
                        <Cimg image={TCR542} />
                        <Cimg image={TCR545} />
                        <Cimg image={TCR554} />
                        <Cimg image={TCR555} />
                        <Cimg image={TCR560_1} />
                        <Cimg image={TCR566} />
                        <Cimg image={TCR567} />
                        <Cimg image={TCR568} />
                        <Cimg image={TCR569_1} />
                        <Cimg image={TCR569_5_1} />
                        <Cimg image={TCR570} />
                        <Cimg image={TCR571B} />
                        <Cimg image={TCR572} />
                        <Cimg image={TCR573} />
                        <Cimg image={TCR574} />
                        <Cimg image={TCR575} />
                        <Cimg image={TCR576} />
                        <Cimg image={TCR576B} />
                        <Cimg image={TCR576p5} />
                        <Cimg image={TCR577} />
                        <Cimg image={TCR578} />
                        <Cimg image={TCRB2} />
                        <Cimg image={TCRB3_1} />
                        <Cimg image={TMC1} />
                        <Cimg image={TMC2} />
                        <Cimg image={TMC3_1} />
                        <Cimg image={TMC4} />
                        <Cimg image={TMC5} />
                        <Cimg image={TMC6} />
                        <Cimg image={TMC7} />
                    </div>
                </div>
            </div>
            {modalUp ? (<div><Footer /></div>
            ) : (<div className='footy'><div className='navi_2'><Footer /></div></div>
            )}
        </div >
    );
}

export default Crown;
