import './crown.css';
import { TPF601, TPF602, TPF603, TPF604, TPF605, TPF606 } from '../catalog_images/micellaneous/door_rail_picture_frame'
import { Cimg } from '../components'

function Miscellaneous5() {
    return (
        <div className="crown">
            <div className='bbcrown_banner'>DOOR RAIL / PICTURE FRAME</div>
            <div className='crown_container'>
                <div className='crown_container_images' >
                    <div className='image_container_container' >
                        <Cimg image={TPF601} className='image_container' />
                        <Cimg image={TPF602} className='image_container' />
                        <Cimg image={TPF603} className='image_container' />
                        <Cimg image={TPF604} className='image_container' />
                        <Cimg image={TPF605} className='image_container' />
                        <Cimg image={TPF606} className='image_container' />
                    </div>
                </div>
            </div>
        </div >
    );
}

export default Miscellaneous5;
