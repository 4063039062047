import './crown.css';
import { HOME_NAVBAR, Footer, Cimg } from '../components'
import { Panelmould2, Panelmould3 } from '../catalog_pages'
import { TPM349, TPM350_5, TPM350, TPM351, TPM352, TPM353, TPM354, TPM355, TPM356, TPM357, TPM358, TPM359_1, TPM360, TPM361, TPM362_1, TPM363, TPM364, TPM365, TPM366, TPM367, TPM368, TPM369, TPM370, TPM371, TPM372, TPM373 } from '../catalog_images/panel_mould/panel_mould'
import { useContext } from 'react'
import { ModalContext } from '../Helper/Context'
function Panelmould() {
    const { modalUp, setModalUp } = useContext(ModalContext);

    return (
        <div className="crown">
            <div className='page_animate' />
            {modalUp ? (<div><HOME_NAVBAR /></div>
            ) : (<div className='navi'><div className='navi_2'><HOME_NAVBAR /></div></div>
            )}
            {modalUp ? (<div className='crown_banner' >PANEL MOULD</div>) : (<div className='crown_banner crowny'>PANEL MOULD</div>)}
            <div className='crown_container'>
                <div className='crown_container_images' >
                    <div className='image_container_container' >
                        <Cimg image={TPM349} className='image_container' />
                        <Cimg image={TPM350_5} className='image_container' />
                        <Cimg image={TPM350} className='image_container' />
                        <Cimg image={TPM351} className='image_container' />
                        <Cimg image={TPM352} className='image_container' />
                        <Cimg image={TPM353} className='image_container' />
                        <Cimg image={TPM354} className='image_container' />
                        <Cimg image={TPM355} className='image_container' />
                        <Cimg image={TPM356} className='image_container' />
                        <Cimg image={TPM357} className='image_container' />
                        <Cimg image={TPM358} className='image_container' />
                        <Cimg image={TPM359_1} className='image_container' />
                        <Cimg image={TPM360} className='image_container' />
                        <Cimg image={TPM361} className='image_container' />
                        <Cimg image={TPM362_1} className='image_container' />
                        <Cimg image={TPM363} className='image_container' />
                        <Cimg image={TPM364} className='image_container' />
                        <Cimg image={TPM365} className='image_container' />
                        <Cimg image={TPM366} className='image_container' />
                        <Cimg image={TPM367} className='image_container' />
                        <Cimg image={TPM368} className='image_container' />
                        <Cimg image={TPM369} className='image_container' />
                        <Cimg image={TPM370} className='image_container' />
                        <Cimg image={TPM371} className='image_container' />
                        <Cimg image={TPM372} className='image_container' />
                        <Cimg image={TPM373} className='image_container' />

                    </div>
                </div>
            </div>
            <Panelmould2 />
            <Panelmould3 />
            {modalUp ? (<div><Footer /></div>
            ) : (<div className='footy'><div className='navi_2'><Footer /></div></div>
            )}
            <div id='footer' />
        </div >
    );
}

export default Panelmould;
