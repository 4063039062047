import React from 'react'
import './things.css'
import { Link } from 'react-router-dom'

function Things(props) {

    return (
        <div className='things'>
            <Link to={props.link}>
                <div className='things_sq1' />
                <div className='things_sq2' />
                <div className='things_sq3'>{props.text}</div>
                <img src={props.image} alt='' className='things_img' />
            </Link>
        </div >
    )
}

export default Things