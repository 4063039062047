import React from 'react'
import './home.css'
import { HOME_NAVBAR, HomeOne, HomeTwo, HomeThree, Footer } from '../components'

const Home = () => {
    return (
        <div className='home'>
            <HOME_NAVBAR />
            <HomeOne />
            <HomeTwo />
            <HomeThree />
            <Footer />
            <div id='footer' />
        </div>
    )
}

export default Home