import React from 'react'
import './redsq2.css'
function Redsq2(props) {
    return (
        <div className='tredsq' style={{ width: props.width + '%', height: props.height + 'vw' }}>
            <div className='tred_sq1' />
            <img src={props.image} className='tred_sq2' />
        </div >
    )
}

export default Redsq2