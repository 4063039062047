import React from 'react'
import './about.css'
import { Link } from 'react-router-dom'
import { HOME_NAVBAR, Redsq, Redsq2, Footer } from '../components'
import { about_storefront, about_accoya, accoya_benefits, accoya_brochure } from '../assets'


const About = () => {
    return (
        <div className='about'>
            <HOME_NAVBAR />
            <div className='about_banner'>ABOUT</div>
            {/* ABOUT */}
            <div className='about_container'>
                <div className='about_left'>
                    <Redsq image={about_storefront} width='100' height='22' />
                </div>
                <div className='about_right'>
                    <h2>
                        At Traditions Mill our goal is to make you happy with our
                        products, timeliness, and customer service.</h2>
                    <p>You can choose from any of the stock profiles in this catalog
                        or bring in your own profile for us to match.
                        We are also able to adjust sizes with some of the profiles,
                        depending on the knife configuration.
                        New profiles are continually added to our product line.
                        Please call us at (801) 426-4822 for the latest additions and
                        pricing. We are happy to answer any questions.
                    </p>
                </div>
            </div>
            <div className='partner_banner'>PARTNERS</div>
            {/* PARTNERS*/}
            <div className='partners'>
                <div className='partners_left'>
                    <p>
                        <span className='partners_left_span'>Accoya® is wood,</span> but not as you know it. It is modified
                        timber in which a process called acetylation, a cutting-edge
                        patented technology, enables it to resist rot, defy the elements
                        and stay strong for decades. Guaranteed for <span className='partners_left_span' >50 years</span> above
                        ground and <span className='partners_left_span' >25 years</span> in ground or freshwater, its performance
                        and properties are remarkable. From joinery for the home to
                        bridges submerged in water, Accoya® long life wood is a
                        leading material that stands up to every application challenge
                        – wood decking, wood cladding, landscape timbers, sidings,
                        wooden windows and more.</p>
                    <div className='accoya_buttons'>
                        <Link to={accoya_brochure} target='blank' download><button className='accoya_btn accoyabtn'>BROCHURE</button></Link>
                        <Link to={accoya_benefits} target='blank' download><button className='accoya_btn'>BENEFITS</button></Link>
                    </div>
                </div>
                <div className='partners_right'>
                    <Redsq2 image={about_accoya} width='70' height='22' className='redsq22' />
                </div>
            </div>

            <Footer />
            <div id='footer' />
        </div >
    )
}

export default About