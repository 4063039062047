import React from 'react'
import './catalog.css'
import { HOME_NAVBAR, Footer } from '../components'
import { Things } from '../components'
import { w_bc, w_cap, w_chairrail, w_cnc, w_crown, w_misc, w_nosing, w_panelmoldb, woodworker, catalog_header_bg } from '../assets'
import { Link } from 'react-router-dom'

const Catalog = () => {
    return (
        <div className='catalog'>
            <HOME_NAVBAR />
            <div className='catalog_banner'>
                <h1>CATALOG</h1>
            </div>
            <div className='catalog_items_container'>
                <div className='home_three_rows'>
                    <div className='home_three_row1'>
                        <Things text='CROWN' image={w_crown} link='/crown' />
                        <Things text='CHAIR RAIL' image={w_chairrail} link='/chairrail' />
                        <Things text='CAP' image={w_cap} link='/cap' />
                    </div>
                    <div className='home_three_row1'>
                        <Things text='BASE & CASE' image={w_bc} link='/basencase' />
                        <Things text='PANEL MOLD' image={w_panelmoldb} link='/panelmould' />
                        <Things text='NOSING' image={w_nosing} link='/nosing' />
                    </div>
                    <div className='home_three_row1_2'>
                        <Things text='MISC' image={w_misc} link='/miscellaneous' />
                        <Things text='CNC WORK' image={w_cnc} link='/cnc' />
                    </div>
                </div>
            </div>

            <Footer />
            <div id='footer' />

        </div>
    )
}

export default Catalog