import React from 'react'
import './home_navbar.css'
import { Link } from 'react-router-dom'
import { logo, hamburgerX } from '../assets'
const HOME_NAVBAR = () => {
    const [toggleMenu, setToggleMenu] = React.useState(false);
    return (
        <div className='navbar'>
            <div className='navbar_left'>
                <Link to='/'><img src={logo} alt='logo' className='navbar_logo' /></Link>
            </div>
            <div className='navbar_right'>
                <Link to='/' className='navbar_links'>HOME</Link>
                <Link to='/catalog' className='navbar_links'>CATALOG</Link>
                <Link to='/gallery' className='navbar_links'>GALLERY</Link>
                <Link to='/about' className='navbar_links'>ABOUT</Link>
                <a href='#footer' className='navbar_links'>CONTACT</a>
            </div>
            <div className='navbar_right_hamburger' onClick={() => setToggleMenu(true)}>
                <span />
                <span />
                <span />
            </div>
            {toggleMenu && (
                <div className='navbar_right_hamburger_clicked'>
                    <img src={hamburgerX} onClick={() => setToggleMenu(false)} />
                    <Link to='/' className='navbar_right_hamburger_clicked_links' onClick={() => setToggleMenu(false)}>HOME</Link>
                    <Link to='/catalog' className='navbar_right_hamburger_clicked_links' onClick={() => setToggleMenu(false)}>CATALOG</Link>
                    <Link to='/gallery' className='navbar_right_hamburger_clicked_links' onClick={() => setToggleMenu(false)}>GALLERY</Link>
                    <Link to='/about' className='navbar_right_hamburger_clicked_links' onClick={() => setToggleMenu(false)}>ABOUT</Link>
                    <a href='#contact' className='navbar_right_hamburger_clicked_links' onClick={() => setToggleMenu(false)}>CONTACT</a>
                </div>
            )}
        </div >
    )
}

export default HOME_NAVBAR