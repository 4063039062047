import './crown.css';
import { TB204A, TB276, TC123, TC131B, TC138, TC146, TC154_1, TC165, TC167, TC171, TC172, TC173, TC174, TC175 } from '../catalog_images/base_n_case/'
import { Cimg } from '../components'

function Basencase3() {
    return (
        <div className="crown">
            <div className='bbcrown_banner'>MISC BASE & CASE</div>
            <div className='crown_container'>
                <div className='crown_container_images' >
                    <div className='image_container_container' >
                        <Cimg image={TB204A} className='image_container' />
                        <Cimg image={TB276} className='image_container' />
                        <Cimg image={TC123} className='image_container' />
                        <Cimg image={TC131B} className='image_container' />
                        <Cimg image={TC138} className='image_container' />
                        <Cimg image={TC146} className='image_container' />
                        <Cimg image={TC154_1} className='image_container' />
                        <Cimg image={TC165} className='image_container' />
                        <Cimg image={TC167} className='image_container' />
                        <Cimg image={TC171} className='image_container' />
                        <Cimg image={TC172} className='image_container' />
                        <Cimg image={TC173} className='image_container' />
                        <Cimg image={TC174} className='image_container' />
                        <Cimg image={TC175} className='image_container' />
                    </div>
                </div>
            </div>
        </div >
    );
}

export default Basencase3;
