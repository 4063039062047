import './cimg.css'
import React from 'react'
import { useState, useContext } from 'react'
import { ModalContext } from '../Helper/Context'

function Cimg(props) {
    const { modalUp, setModalUp } = useContext(ModalContext);
    const [modal1, setModal1] = useState(false);

    return (
        <div className='cimg'>

            {modal1 && (<div className='cmodal' onClick={() => { setModal1(false); setModalUp(true) }}><div className='cmodal_inner'><img src={props.image} alt='' /></div></div>)}
            <img src={props.image} className='image_cont' onClick={() => { setModal1(true); setModalUp(false) }} />
        </div>
    )
}

export default Cimg