import React from 'react'
import './hometwo.css'
import { Things } from '../components'
import { w_bc, w_cap, w_chairrail, w_cnc, w_crown, w_misc, w_nosing, w_panelmoldb, w_s4s } from '../assets'

const HomeTwo = () => {
    return (
        <div className='home_two'>
            <div className='home_two_text'>
                <div className='home_two_text_1'>
                    <h1>MOULDINGS</h1>
                    <p>We have profiles for all hardwood
                        applications. We can match any
                        profile that fits your needs. Whether
                        you need eight, or thousands of feet,
                        we can help you with your project</p>

                </div>
                <div className='home_two_text_2'>
                    <h1>SURFACED 4 SIDES</h1>
                    <p>We make surfaced 4 sides. The most common sizes are 1x4,
                        1x6, 1x8, 1x10, and 1x12 We can however
                        make any size needed for your application</p>
                </div>
                <div className='home_two_text_3'>
                    <h1>CNC WORK</h1>
                    <p>From the unique drawer front to
                        a beautiful sign to display on your
                        home, we can do it all. For those
                        unique one of a kind projects, give
                        us a call</p>
                </div>
            </div>
            <div className='home_two_w'>
                <Things text='MOULDINGS' image={w_crown} link='/catalog' />
                <Things text='S4S' image={w_s4s} link='/catalog' />
                <Things text='CNC WORK' image={w_cnc} link='/catalog' />
            </div>
        </div>
    )
}

export default HomeTwo