import './crown.css';
import { HOME_NAVBAR, Footer, Cimg } from '../components'
import { TN300, TN301, TN305, TN306, TN308, TN310, TN320 } from '../catalog_images/nosing/'
import { useContext } from 'react'
import { ModalContext } from '../Helper/Context'
function Nosing() {
    const { modalUp, setModalUp } = useContext(ModalContext);

    return (
        <div className="crown">
            <div className='page_animate' />
            {modalUp ? (<div><HOME_NAVBAR /></div>
            ) : (<div className='navi'><div className='navi_2'><HOME_NAVBAR /></div></div>
            )}
            {modalUp ? (<div className='crown_banner' >NOSING</div>) : (<div className='crown_banner crowny'>NOSING</div>)}
            <div className='crown_container'>
                <div className='crown_container_images' >
                    <div className='image_container_container' >
                        <Cimg image={TN300} className='image_container' />
                        <Cimg image={TN301} className='image_container' />
                        <Cimg image={TN305} className='image_container' />
                        <Cimg image={TN306} className='image_container' />
                        <Cimg image={TN308} className='image_container' />
                        <Cimg image={TN310} className='image_container' />
                        <Cimg image={TN320} className='image_container' />
                    </div>
                </div>
            </div>
            {modalUp ? (<div><Footer /></div>
            ) : (<div className='footy'><div className='navi_2'><Footer /></div></div>
            )}
            <div id='footer' />
        </div >
    );
}

export default Nosing;
