import './crown.css';
import { HOME_NAVBAR, Footer, Cimg } from '../components'
import { TCP949, TCP950, TCP951, TCP952, TCP953, TCP953B, TCP954, TCP955, TCP956, TCP957, TCP958, TCP959, TCP960_5, TCP960, TCP961_1, TCP962, TCP963, TCP964, TCP965, TCP965B, TCP966, TCP967, TCP968, TCP969, TCP970, TCP971, TCP972, TCP973, TCP974, TCP975, TCP976, TCP977, TCP978, TCP979, TCP980, TCP981, TCP982, TCP983, TCP984, TCP985, TCP986, TCP987 } from '../catalog_images/cap/'
import { useContext } from 'react'
import { ModalContext } from '../Helper/Context'
function Cap() {
    const { modalUp, setModalUp } = useContext(ModalContext);

    return (
        <div className="crown">
            <div className='page_animate' />
            {modalUp ? (<div><HOME_NAVBAR /></div>
            ) : (<div className='navi'><div className='navi_2'><HOME_NAVBAR /></div></div>
            )}
            {modalUp ? (<div className='crown_banner' >CAP</div>) : (<div className='crown_banner crowny'>CAP</div>)}
            <div className='crown_container'>
                <div className='crown_container_images' >
                    <div className='image_container_container' >
                        <Cimg image={TCP949} className='image_container' />
                        <Cimg image={TCP950} className='image_container' />
                        <Cimg image={TCP951} className='image_container' />
                        <Cimg image={TCP952} className='image_container' />
                        <Cimg image={TCP953} className='image_container' />
                        <Cimg image={TCP953B} className='image_container' />
                        <Cimg image={TCP954} className='image_container' />
                        <Cimg image={TCP955} className='image_container' />
                        <Cimg image={TCP956} className='image_container' />
                        <Cimg image={TCP956} className='image_container' />
                        <Cimg image={TCP957} className='image_container' />
                        <Cimg image={TCP958} className='image_container' />
                        <Cimg image={TCP959} className='image_container' />
                        <Cimg image={TCP960} className='image_container' />
                        <Cimg image={TCP960_5} className='image_container' />
                        <Cimg image={TCP961_1} className='image_container' />
                        <Cimg image={TCP962} className='image_container' />
                        <Cimg image={TCP963} className='image_container' />
                        <Cimg image={TCP964} className='image_container' />
                        <Cimg image={TCP965} className='image_container' />
                        <Cimg image={TCP965B} className='image_container' />
                        <Cimg image={TCP966} className='image_container' />
                        <Cimg image={TCP967} className='image_container' />
                        <Cimg image={TCP968} className='image_container' />
                        <Cimg image={TCP969} className='image_container' />
                        <Cimg image={TCP970} className='image_container' />
                        <Cimg image={TCP971} className='image_container' />
                        <Cimg image={TCP972} className='image_container' />
                        <Cimg image={TCP973} className='image_container' />
                        <Cimg image={TCP974} className='image_container' />
                        <Cimg image={TCP975} className='image_container' />
                        <Cimg image={TCP976} className='image_container' />
                        <Cimg image={TCP977} className='image_container' />
                        <Cimg image={TCP978} className='image_container' />
                        <Cimg image={TCP979} className='image_container' />
                        <Cimg image={TCP980} className='image_container' />
                        <Cimg image={TCP981} className='image_container' />
                        <Cimg image={TCP982} className='image_container' />
                        <Cimg image={TCP983} className='image_container' />
                        <Cimg image={TCP984} className='image_container' />
                        <Cimg image={TCP985} className='image_container' />
                        <Cimg image={TCP986} className='image_container' />
                        <Cimg image={TCP987} className='image_container' />
                    </div>
                </div>


            </div>
            {modalUp ? (<div><Footer /></div>
            ) : (<div className='footy'><div className='navi_2'><Footer /></div></div>
            )}
            <div id='footer' />
        </div >
    );
}

export default Cap;