import './crown.css';
import { TG, TG_1, Shiplap_7p00, Shiplap7p500, Shiplap7p25, Beaded_TG } from '../catalog_images/micellaneous/siding'
import { Cimg } from '../components'

function Miscellaneous2() {
    return (
        <div className="crown">
            <div className='bbcrown_banner'>SIDING</div>
            <div className='crown_container'>
                <div className='crown_container_images' >
                    <div className='image_container_container' >
                        <Cimg image={TG} className='image_container' />
                        <Cimg image={Beaded_TG} className='image_container' />
                        <Cimg image={Shiplap_7p00} className='image_container' />
                        <Cimg image={Shiplap7p25} className='image_container' />
                        <Cimg image={Shiplap7p500} className='image_container' />
                        <Cimg image={TG_1} className='image_container' />
                    </div>
                </div>
            </div>
        </div >
    );
}

export default Miscellaneous2;
