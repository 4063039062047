import React from 'react'
import './contact.css'
const Contact = () => {
    return (
        <div>Contact

            <div id='footer' />

        </div>

    )
}

export default Contact