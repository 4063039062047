import React from 'react'
import './redsq.css'


function Redsq(props) {

    return (
        <div className='redsq' style={{ width: props.width + '%', height: props.height + 'vw' }}>
            <div className='red_sq1' />
            <img src={props.image} className='red_sq2' />
        </div >
    )
}

export default Redsq