import './crown.css';
import { TC151, TC152, TC153, TC155, TC157, TC183, TC185, TC186 } from '../catalog_images/micellaneous/architrave'
import { Cimg } from '../components'


function Miscellaneous3() {
    return (
        <div className="crown">
            <div className='bbcrown_banner'>ARCHITRAVE</div>
            <div className='crown_container'>
                <div className='crown_container_images' >
                    <div className='image_container_container' >
                        <Cimg image={TC151} className='image_container' />
                        <Cimg image={TC152} className='image_container' />
                        <Cimg image={TC153} className='image_container' />
                        <Cimg image={TC155} className='image_container' />
                        <Cimg image={TC157} className='image_container' />
                        <Cimg image={TC183} className='image_container' />
                        <Cimg image={TC185} className='image_container' />
                        <Cimg image={TC186} className='image_container' />
                    </div>
                </div>
            </div>
        </div >
    );
}

export default Miscellaneous3;
