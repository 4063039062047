import './crown.css';
import { TP900, TP905_1, TP906, TP907, TP908 } from '../catalog_images/panel_mould/pencil_mould'


function Panelmould2() {
    return (
        <div className="crown">
            <div className='bbcrown_banner'>PENCIL MOULD</div>
            <div className='crown_container'>
                <div className='crown_container_images' >
                    <div className='image_container_container' >
                        <img src={TP900} className='image_container' />
                        <img src={TP905_1} className='image_container' />
                        <img src={TP906} className='image_container' />
                        <img src={TP907} className='image_container' />
                        <img src={TP908} className='image_container' />
                    </div>
                </div>
            </div>
        </div >
    );
}

export default Panelmould2;
