import React from 'react'
import './homeone.css'
import { Link } from 'react-router-dom'
import { gear } from '../assets'
const HomeOne = () => {
    return (
        <div className='home_one'>
            <div className='home_one_text'>
                <h1>REDEFINING 'RUN OF THE MILL'</h1>
                <p className='home_one_text_two'>Traditions Mill specializes in manufacturing of custom hardwood moldings.</p>
                <p className='home_one_text_three'>No job too large or too small</p>
            </div>
            <button className='home_one_button'><Link to='/catalog'>CATALOG</Link></button>
            <img src={gear} alt='' className='home_one_gear_one' />
            <img src={gear} alt='' className='home_one_gear_two' />
            <div className='home_one_diag'>SERVICES</div>
        </div>
    )
}

export default HomeOne