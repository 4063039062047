import React from 'react'
import './gallery.css'
import { HOME_NAVBAR, Footer } from '../components'
import { g_img1, g_img2, g_img3, g_img4, g_img5, g_img6, g_img7, g_img8, g_img9, g_img10, g_img11, g_img12, g_img13 } from '../assets'
import { useState } from 'react'
const Gallery = () => {

    const [modal2, setModal2] = useState(false);
    const [modal3, setModal3] = useState(false);
    const [modal4, setModal4] = useState(false);
    const [modal5, setModal5] = useState(false);
    const [modal6, setModal6] = useState(false);
    const [modal7, setModal7] = useState(false);
    const [modal8, setModal8] = useState(false);
    const [modal9, setModal9] = useState(false);
    const [modal10, setModal10] = useState(false);
    const [modal11, setModal11] = useState(false);
    const [modal12, setModal12] = useState(false);
    const [modal13, setModal13] = useState(false);
    const [modal1, setModal1] = useState(false);


    return (
        <div className='gallery'>
            {modal2 && (<div className='modal' onClick={() => setModal2(false)}><div className='modal_inner'><img src={g_img2} alt='' /></div></div>)}
            {modal3 && (<div className='modal' onClick={() => setModal3(false)}><div className='modal_inner'><img src={g_img3} alt='' /></div></div>)}
            {modal4 && (<div className='modal' onClick={() => setModal4(false)}><div className='modal_inner'><img src={g_img4} alt='' /></div></div>)}
            {modal5 && (<div className='modal' onClick={() => setModal5(false)}><div className='modal_inner'><img src={g_img5} alt='' /></div></div>)}
            {modal6 && (<div className='modal' onClick={() => setModal6(false)}><div className='modal_inner'><img src={g_img6} alt='' /></div></div>)}
            {modal7 && (<div className='modal' onClick={() => setModal7(false)}><div className='modal_inner'><img src={g_img7} alt='' /></div></div>)}
            {modal8 && (<div className='modal' onClick={() => setModal8(false)}><div className='modal_inner'><img src={g_img8} alt='' /></div></div>)}
            {modal9 && (<div className='modal' onClick={() => setModal9(false)}><div className='modal_inner'><img src={g_img9} alt='' /></div></div>)}
            {modal10 && (<div className='modal' onClick={() => setModal10(false)}><div className='modal_inner'><img src={g_img10} alt='' /></div></div>)}
            {modal11 && (<div className='modal' onClick={() => setModal11(false)}><div className='modal_inner'><img src={g_img11} alt='' /></div></div>)}
            {modal12 && (<div className='modal' onClick={() => setModal12(false)}><div className='modal_inner'><img src={g_img12} alt='' /></div></div>)}
            {modal13 && (<div className='modal' onClick={() => setModal13(false)}><div className='modal_inner'><img src={g_img13} alt='' /></div></div>)}
            {modal1 && (<div className='modal' onClick={() => setModal1(false)}><div className='modal_inner'><img src={g_img1} alt='' /></div></div>)}


            <HOME_NAVBAR />
            <div className='catalog_banner' >
                <h1>GALLERY</h1>
            </div>
            <div className='gallery_container'>
                <div className='gallery_row1'>
                    <img src={g_img2} alt='' onClick={() => setModal2(true)} />
                    <img src={g_img3} alt='' onClick={() => setModal3(true)} />
                    <img src={g_img4} alt='' onClick={() => setModal4(true)} />
                </div>
                <div className='gallery_row2'>
                    <img src={g_img5} alt='' onClick={() => setModal5(true)} />
                    <img src={g_img6} alt='' onClick={() => setModal6(true)} />
                </div>
                <div className='gallery_row3'>
                    <img src={g_img7} alt='' onClick={() => setModal7(true)} />
                    <img src={g_img8} alt='' onClick={() => setModal8(true)} />
                    <img src={g_img9} alt='' onClick={() => setModal9(true)} />
                    <img src={g_img10} alt='' onClick={() => setModal10(true)} />
                </div>
                <div className='gallery_row4'>
                    <img src={g_img1} alt='' onClick={() => setModal1(true)} />
                    <img src={g_img11} alt='' onClick={() => setModal11(true)} />
                    <img src={g_img12} alt='' onClick={() => setModal12(true)} />
                    <img src={g_img13} alt='' onClick={() => setModal13(true)} />
                </div>
            </div>

            <Footer />
            <div id='footer' />
        </div>
    )
}

export default Gallery