import React from 'react'
import './homethree.css'
import { Things } from '../components'
import { w_bc, w_cap, w_chairrail, w_cnc, w_crown, w_misc, w_nosing, w_panelmoldb, woodworker } from '../assets'

const HomeThree = () => {

    return (
        <div className='home_three'>
            <div className='home_three_bar'>WORK</div>
            <img className='home_three_banner' alt='' src={woodworker} />
            <div className='home_three_rows'>
                <div className='home_three_row1'>
                    <Things text='CROWN' image={w_crown} link='/crown' />
                    <Things text='CHAIR RAIL' image={w_chairrail} link='/chairrail' />
                    <Things text='CAP' image={w_cap} link='/cap' />
                </div>
                <div className='home_three_row1'>
                    <Things text='BASE & CASE' image={w_bc} link='/basencase' />
                    <Things text='PANEL MOLD' image={w_panelmoldb} link='/panelmould' />
                    <Things text='NOSING' image={w_nosing} link='/nosing' />
                </div>
                <div className='home_three_row1_2'>
                    <Things text='MISC' image={w_misc} link='/miscellaneous' />
                    <Things text='CNC WORK' image={w_cnc} link='/cnc' />
                </div>
            </div>
        </div>
    )
}

export default HomeThree