import './crown.css';
import { HOME_NAVBAR, Footer, Cimg } from '../components'
import { TCH915, TCH920, TCH921, TCH922, TCH923, TCH924, TCH925, TCH926, TCH927, TCH928, } from '../catalog_images/chair_rail'
import { useContext } from 'react'
import { ModalContext } from '../Helper/Context'

function Chairrail() {
    const { modalUp, setModalUp } = useContext(ModalContext);

    return (
        <div className="crown">
            <div className='page_animate' />
            {modalUp ? (<div><HOME_NAVBAR /></div>
            ) : (<div className='navi'><div className='navi_2'><HOME_NAVBAR /></div></div>
            )}
            {modalUp ? (<div className='crown_banner' >CHAIR RAIL</div>) : (<div className='crown_banner crowny'>CHAIR RAIL</div>)}
            <div className='crown_container'>
                <div className='crown_container_images' >
                    <div className='image_container_container' >
                        <Cimg image={TCH915} className='image_container' />
                        <Cimg image={TCH920} className='image_container' />
                        <Cimg image={TCH921} className='image_container' />
                        <Cimg image={TCH922} className='image_container' />
                        <Cimg image={TCH923} className='image_container' />
                        <Cimg image={TCH924} className='image_container' />
                        <Cimg image={TCH925} className='image_container' />
                        <Cimg image={TCH926} className='image_container' />
                        <Cimg image={TCH927} className='image_container' />
                        <Cimg image={TCH928} className='image_container' />
                    </div>
                </div>


            </div>
            {modalUp ? (<div><Footer /></div>
            ) : (<div className='footy'><div className='navi_2'><Footer /></div></div>
            )}
            <div id='footer' />
        </div >
    );
}

export default Chairrail;