import './crown.css';
import { CB_Meat_Board, CB_Customizable, CB_Circle_With_Flute, DSC_0109, DSC_0111, DSC_0113 } from '../catalog_images/cnc/cutting_boards'
import { Cimg } from '../components'

function Cnc2() {
    return (
        <div className="crown">
            <div className='bbcrown_banner'>CUTTING BOARDS</div>
            <div className='crown_container'>
                <div className='crown_container_images' >
                    <div className='image_container_container' >
                        <Cimg image={CB_Meat_Board} className='image_container' />
                        <Cimg image={CB_Customizable} className='image_container' />
                        <Cimg image={CB_Circle_With_Flute} className='image_container' />
                        <Cimg image={DSC_0109} className='image_container' />
                        <Cimg image={DSC_0111} className='image_container' />
                        <Cimg image={DSC_0113} className='image_container' />
                    </div>
                </div>
            </div>
        </div >
    );
}

export default Cnc2;
