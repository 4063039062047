import React from 'react'
import { useState } from 'react'
import './login.css'
import { signInWithEmailAndPassword } from 'firebase/auth'
import { auth } from '../firebase'
import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import { AuthContext } from "../context/AuthContext";

function Login() {
    const [error, setError] = useState(false);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    const navitage = useNavigate()

    const { dispatch } = useContext(AuthContext)

    const handleLogin = (e) => {
        e.preventDefault();

        signInWithEmailAndPassword(auth, email, password)
            .then((userCredential) => {
                // Signed in
                const user = userCredential.user;
                dispatch({ type: "LOGIN", payload: user })
                navitage("/admin")
            })
            .catch((error) => {
                setError(true);
            });
    };
    return (
        <div className='login'>
            <form onSubmit={handleLogin}>
                <label htmlFor="">TRADITIONSMILL ADMIN</label>
                <input type='email' placeholder='EMAIL' onChange={e => setEmail(e.target.value)}></input>
                <input type='password' placeholder='PASSWORD' onChange={e => setPassword(e.target.value)}></input>
                <button type='submit'>LOGIN</button>
                {error && <span>WRONG EMAIL OR PASSWORD</span>}
            </form>
        </div>
    )
}

export default Login